<template>
  <div id="signPreview">
    <table border="0">
      <tbody>

      <tr border="0" style="height: 54pt;">
        <td border="0"
            style="font-family:Helvetica, Arial, sans-serif; margin: 0pt;  vertical-align: top; padding: 0; overflow: hidden;">
          <span style="font-size: 11pt; color: #000000; background-color: transparent; vertical-align: baseline;">
                <img style="object-fit:cover;border-radius: 50%;" width="60" height="60" :src="formData.image"/>
                <img v-if="isGranted('ROLE_GENERATE_SIGNATURE_AS_BLACKINK')" style="object-fit:cover;background-color: white;padding: 2px;border-radius: 25%;position: absolute;margin-top: 42px;margin-left: -18px;"
                     width="30" height="20" src="@/assets/icons/logo-blackInk.png"/>
                <img v-else style="object-fit:cover;border-radius: 50%;position: absolute;margin-top: 42px;margin-left: -18px;"
                     width="20" height="20" src="@/assets/icons/favicon-white.png"/>
          </span>
        </td>
        <td border="0" colspan="2"
            style="font-family:Helvetica, Arial, sans-serif; margin: 0pt; vertical-align: top; padding-left: 5pt; overflow: hidden;">
          <p dir="ltr" style="margin-bottom: 0; margin-top: 0pt;">
            <span
              style="line-height: 1.4; margin-top: 0; margin-bottom: 0pt; font-size: 9pt; font-family:Helvetica, Arial, sans-serif; color:#777777; background-color: transparent; vertical-align:top;">Clear Mortgage Capital, Inc.</span>
            <br>
            <span
              style="line-height: 1.8px; font-size: 14pt; font-family:Helvetica, Arial, sans-serif; color: #000000; background-color: transparent; font-weight: 700; vertical-align: baseline;">{{ formData.name }}</span>
            <br>
            <span
              style="line-height: 1.4px; font-size: 10pt; line-height: 1.4; margin-top: 4pt;font-family:Helvetica, Arial, sans-serif; color:#000000; background-color: transparent;">{{ formData.position }} &nbsp;• &nbsp;NMLS {{ formData.nmls }}</span>
            <br>

          </p>
        </td>
      </tr>

      <tr border="0" style="height: 35pt;">
        <td border="0"
            style="font-family:Helvetica, Arial, sans-serif; margin: 0px;  vertical-align: top; padding: 0pt; overflow: hidden;">
          <br>
        </td>
        <td border="0" colspan="2"
            style="width: 350px; padding-left: 5pt; font-family:Helvetica, Arial, sans-serif; margin: 0px;  vertical-align: top; padding-bottom: 5pt; overflow: hidden;">

          <p dir="ltr"
             style="font-size: 10pt; font-family:Helvetica, Arial, sans-serif; line-height: 1.4; margin-top: 10pt !important; margin-bottom: 0pt; background-color: transparent; font-weight: 700; vertical-align: baseline;">
            <a :href="formData.applyUrl" target="_blank" onMouseOver="this.style.color='#0E1531'"
               onMouseOut="this.style.color='#05A1CA'" style="color:#05A1CA;text-decoration: underline;">
              Apply for Loan
            </a>
            <span style="margin-right: 10pt;"></span>
            <a :href="formData.sendDocsUrl" target="_blank" onMouseOver="this.style.color='#0E1531'"
               onMouseOut="this.style.color='#05A1CA'" style="color:#05A1CA;text-decoration: underline;">
              Send Documents
            </a>
            <span style="margin-right: 10pt;"></span>
            <a :href="formData.getQuoteUrl" v-if="formData.showRateQuoteFunnel" target="_blank" onMouseOver="this.style.color='#0E1531'"
               onMouseOut="this.style.color='#05A1CA'" style="color:#05A1CA;text-decoration: underline;">
              Get a Quote
            </a>
          </p>
        </td>
      </tr>

      <tr border="0">
        <td style="height: 30px"></td>
        <td style="width: 25px; padding-left: 5pt; vertical-align: top;">
          <img
            src="https://lh6.googleusercontent.com/hC4O7pYPeEohR5ohFNNB2hfMKS7ZCOW6QoREQcS_Cs6A5PF8ZUUKhhQHj-hpiiP58k14PzI5dStsKc6DjqXtJMNGqHbOYbo2wNUDUtu4D3JxJ3dppMZvMVZPkVor4ACToQ1KaySw"
            width="12" height="12">
        </td>
        <td style="vertical-align: top">
          <p style="font-size: 10pt; font-family:Helvetica, Arial, sans-serif; line-height: 1.4; margin: 0">
            <a :href="'tel:' + formData.phone" onMouseOver="this.style.color='#0F152F'"
               onMouseOut="this.style.color='#777777'"
               style="color:#777777;text-decoration: underline;">{{ formData.phone }}</a>
          </p>
        </td>
      </tr>
      <tr border="0">
        <td style="height: 30px"></td>
        <td style="width: 25px; padding-left: 5pt; vertical-align: top;">
          <img
            src="https://lh3.googleusercontent.com/-8RVzp0eZt__UK5EpSbaHtXI-GHiHsnUNYmdpkCeUK9EZJJ8j3QmjqfU9n5tUtne2EZHaaH17i4vX91cxWBUOqG4Xr_0jN_9YewmAt1ZQqVXnkonIhmD4ZxsJ2JFrhfc0J53hQ9Y"
            width="12" height="12">
        </td>
        <td style="vertical-align: top">
          <p style="font-size: 10pt; font-family:Helvetica, Arial, sans-serif; line-height: 1.4; margin: 0">
            <a :href="backendUrl(formData.webSite)" target="_blank" onMouseOver="this.style.color='#0F152F'"
               onMouseOut="this.style.color='#777777'"
               style="color:#777777;text-decoration: underline;">https://www.clearmortgagecapital.com/</a>
          </p>
        </td>
      </tr>
      <tr border="0">
        <td style="height:55px"></td>
        <td style="width: 25px; padding-left: 5pt; vertical-align: top;">
          <img
            src="https://lh3.googleusercontent.com/RcBlNTMivk02JMq8KOevB8ZOPn62z7LleyHKt06gn--mNhFTMmvkwheaeiR-OtcWPrrEMXFHc2fxyguOIAab7w2lXOkGqLo7WUuikuQCOJocDA9ZPA7xIJ9ZDVT0ozD356Lu6XF0"
            width="12" height="12">
        </td>
        <td style="vertical-align: top">
          <p
            style="color: #777777;font-size: 10pt; font-family:Helvetica, Arial, sans-serif; line-height: 1.4; margin: 0; padding-top: 2px">
            19800 MacArthur Blvd, Suite 880
            <br>
            Irvine, CA 92612
          </p>
        </td>
      </tr>
      </tbody>
    </table>
    <p class="preview-company">
      <span>Clear Mortgage Capital, Inc. 1935005</span>
    </p>
    <p dir="ltr" class="preview-notice">
      <span>Confidentiality Notice: This email message, including any attachments, is for
                    the sole use of the intended recipient(s) and may contain confidential and privileged information. Any unauthorized review, use, disclosure or
                    distribution of this information is prohibited, and may be punishable by law. If this was sent to you in error,
                    please notify the sender by reply email and destroy all copies of the original message. Please consider the environment before printing this email. Equal Opportunity Lender. www.nmlsconsumeraccess.org
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'SignPreview',
  props: ['formData']
}
</script>

<style lang="scss" scoped>
#signPreview {
  table {
    font-style: normal;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 0;
    tr {
      td {
        &:first-child {
          width: 19%;
        }
      }
    }
  }

  .preview-company {
    margin: 0;
    padding: 0;

    span {
      line-height: 1.4;
      margin-top: 7pt;
      font-size: 9pt;
      font-family: Helvetica, Arial, sans-serif;
      color: #777777;
      background-color: transparent;
      vertical-align: baseline;
    }
  }

  .preview-notice {
    margin: 0;
    padding: 0;
    color: #777777;
    font-family: Helvetica, Arial, sans-serif;
    font-size: small;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
    letter-spacing: normal;
    orphans: 2;
    text-align: -webkit-left;
    text-indent: 0px;
    text-transform: none;
    white-space: normal;
    widows: 2;
    word-spacing: 0px;
    -webkit-text-stroke-width: 0px;
    background-color: rgb(255, 255, 255);
    text-decoration-style: initial;
    text-decoration-color: initial;
    line-height: 1.728;
    margin-top: 10pt;
    margin-bottom: 0pt;

    span {
      font-size: 9pt;
      font-family: Roboto, sans-serif;
      color: rgb(142, 148, 152);
      background-color: transparent;
      font-style: italic;
      vertical-align: baseline;
    }
  }
}
</style>
