<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Wire Instructions</h3>
        <button class="btn btn-control" @click="$emit('close')">
          <img src="@/assets/icons/icon-remove.svg" alt="">
        </button>
      </div>
      <div class="modal-body pb-0">
        <div class="modal-body-container border-bottom-0">
          <p>
            Wire Instructions for Broker Compensation to Clear Mortgage Capital, Inc.:
          </p>
          <p class="wire-instruction__text">
            Bank: JP Morgan Chase, Irvine, CA<br>
            Routing: 322271627<br>
            Account: 828785621<br>
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <base-button title="Copy Text" action="secondary-default" @click-btn="copyText" :loading="ui.copying" />
        <base-button title="Close" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';
export default {
  components: { BaseButton },
  name: 'WireInstructionModal',
  data() {
    return {
      ui: {
        copying: false
      }
    }
  },
  methods: {
    copyText() {
      this.ui.copying = true;
      this.$nextTick(() => {
        const textArea = document.createElement("textarea");
        let text = document.getElementsByClassName('wire-instruction__text')[0].innerHTML;

        text = text.replace(/(<([^>]+)>)/gi, "");

        textArea.value = text;

        document.body.appendChild(textArea);

        textArea.focus();
        textArea.select();

        try {
          const success = document.execCommand('copy'),
                msg = success ? 'successful' : 'unsuccessful';
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Copying text command was ' + msg,
            status: 'success',
            delay: 2000
          });
        } catch (err) {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: err,
            status: 'error',
            delay: 2000
          });
        }

        setTimeout(() => {this.ui.copying = false}, 1000)

        document.body.removeChild(textArea)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  &-block {
    height: fit-content!important;
    margin: 150px auto!important;
    overflow-y: hidden !important;

    p {
      color: #000000;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }
    .modal-footer {
      bottom: unset;
    }
  }
}
</style>
