<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Email signature</h3>
        <button class="btn btn-control" v-if="isGranted('ROLE_CEO')" @click="$emit('custom')">
          <img src="@/assets/icons/icon-edit-normal.svg" alt="">
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-bottom-0" v-if="!croped">
          <div class="row">
            <div class="col-md-5">
              <vue-cropper
                ref="cropper"
                :src="formData.image"
                :aspect-ratio="1/1"
                alt="Source Image"
                preview=".preview"
              >
              </vue-cropper>
              <div class="preview"></div>
              <base-button title="Crop & Preview" action="secondary" click-btn="cropImage" class="mt-3" />
              <div class="form-group mt-3">
                <input
                  type="checkbox"
                  name=""
                  id="showRateQuote"
                  class="form-check-input m-0"
                  v-model="formData.showRateQuoteFunnel"
                  :value="formData.showRateQuoteFunnel"
                />
                <label for="showRateQuote">Add Rate Quote Funnel link</label>
              </div>
            </div>
            <div class="col-md-7 mt-5 mt-md-0">
              <sign-preview :form-data="formData" />
            </div>
          </div>
        </div>
        <div class="modal-body-container border-bottom-0" v-else>
          <ul>
            <li>1. Click <strong>Download</strong></li>
            <li>2. Open the <strong>“signature.html”</strong> link at the bottom of your browser</li>
            <li>3. Highlight and <strong>Copy</strong> the signature</li>
            <li>4. Go to Outlook and <strong>Paste</strong> into email signature</li>
            <li>5. <strong>Save</strong> as default email signature</li>
          </ul>
        </div>
      </div>
      <div class="modal-footer justify-content-end">
        <div class="d-flex">
          <base-button title="Crop & Next" action="secondary-default" @click-btn="cropNext" v-if="!croped" />
          <base-button title="Download" action="secondary-default" @click-btn="downloadSignature" v-else :disabled="isDownload" :loading="isDownload" />
        </div>
        <div>
          <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import SignPreview from "../includes/SignPreview";
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: 'ESignGeneratingModal',
  components: {SignPreview, VueCropper, BaseButton },
  data() {
    return {
      isDownload: false,
      image: this.agentPhoto(this.$store.getters.getPhoto),
      formData: {
        image: this.agentPhoto(this.$store.getters.getPhoto),
        name: 'Full Name',
        position: this.$store.getters.getPosition,
        nmls: '',
        phone: '',
        webSiteUrl: 'https://www.clearmortgagecapital.com',
        applyUrl: '#',
        sendDocsUrl: '#',
        getQuoteUrl: '#',
        showRateQuoteFunnel: false,
      },
      croped: false
    }
  },
  beforeMount() {
    this.getAgentData()
  },
  methods: {
    getAgentData() {
      this.$http.get('/api/v1/user/signature/get-data')
        .then((res) => {
          this.formData.name = res.data.fullName
          this.formData.nmls = res.data.nmls
          this.formData.applyUrl = res.data.applyUrl
          this.formData.sendDocsUrl = this.backendUrl(res.data.sendDocsUrl)
          this.formData.phone = this.formatUSNumber(res.data.phone)
          this.formData.webSite = res.data.websiteUrl
        })
    },
    cropImage() {
      this.formData.image = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    cropNext() {
      this.formData.image = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.croped = true
      this.uploadImage()
    },
    uploadImage() {
      this.getRoundedCanvas(this.$refs.cropper.getCroppedCanvas()).toBlob((blob) => {
        let formData = new FormData()
        formData.append("file", blob)

        this.$http.post("/api/v1/user/upload-photo", formData)
      })
    },
    downloadSignature() {
      this.isDownload = true
      this.$http
        .get('/api/v1/user/signature', {
          params: {
            showRateQuoteFunnel: this.formData.showRateQuoteFunnel
          }
        })
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'signature.html');
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch(err => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.isDownload = false
        })
    },
    getRoundedCanvas(sourceCanvas) {
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      var width = sourceCanvas.width;
      var height = sourceCanvas.height;

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = 'destination-in';
      context.beginPath();
      context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
      context.fill();
      return canvas;
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-center {
  overflow: auto;
  .modal-block {
    margin: 100px auto;
    width: 800px !important;
    @media screen and (max-width: 568px) {
      margin: 0 auto;
    }
    height: fit-content;
    overflow: auto;

    .modal-header {
      position: unset;
    }

    .modal-body {
      padding-bottom: 0;
      @media screen and (max-width: 568px) {
        height: 100vh;
        overflow: scroll;
      }
      ul {
        list-style: none;
        padding: 0 0 110px 0;
        li {
          padding: 15px 0;
          border-bottom: 1px solid #dee2e6;
        }
      }
    }
    .modal-footer {
      position: unset;
      bottom: unset;
      @media screen and (max-width: 568px) {
        bottom: 0;
      }
    }
  }
}

::v-deep {
  .cropper-view-box {
    border-radius: 50%;
  }
  .cropper-face {
    background-color: inherit !important;
  }
}
.form-check-input {
  width: 18px;
  height: 18px;
  max-width: 18px;
  max-height: 18px;
  margin-right: 10px !important;
}
</style>
