<template>
  <div>
    <textarea id="htmlText" v-model="msg" rows="15"></textarea>
  </div>
</template>

<script>
import { HtmlOutputTemplate } from './template.js';
export default {
  name: 'HtmlOutput',
  props: ['formData'],
  data() {
    return {
      msg: HtmlOutputTemplate(this.formData)
    }
  }
}
</script>
