export const HtmlOutputTemplate = (data) => {
  return(
    `<!doctype html>\n`+
  `<html>\n`+
  `<head>\n`+
  `  <meta charset="utf-8">\n`+
  `    <title>Email Signature</title>\n`+
  `    <link rel="stylesheet" href="http://fonts.googleapis.com/css?family=Montserrat">\n`+
  `</head>\n`+

  `<body>\n`+
  `<table border="0" style="font-style: normal; font-family:Helvetica, Arial, sans-serif;margin-bottom: 0;">\n`+
  `  <tbody border="0" >\n`+
  `  <tr border="0" style="height: 54pt;">\n`+
  `    <td border="0" style="font-family:Helvetica, Arial, sans-serif; margin: 0pt;  vertical-align: top; padding: 0; overflow: hidden;">\n`+
  `            <span style="font-size: 11pt; color: #000000; background-color: transparent; vertical-align: baseline;">\n`+
  `                  <img width="60" height="61" style="border-radius: 50%; object-fit: cover;" src="${data.image}" />\n`+
  `    </span>\n`+
  `  </td\n>`+
  `  <td border="0" colspan="2" style="font-family:Helvetica, Arial, sans-serif; margin: 0pt; border-color: transparent transparent rgba(119, 119, 119, 0.11); vertical-align: top; padding-left: 5pt; overflow: hidden;">\n`+
  `    <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt;">\n`+
  `      <span style="line-height: 1.4; margin-top: 0; margin-bottom: 0pt; font-size: 9pt; font-family:Helvetica, Arial, sans-serif; color:#777777; background-color: transparent; vertical-align:top;">Clear Mortgage Capital, Inc.</span>\n`+
  `      <br>\n`+
  `        <span style="line-height: 1.8;font-size: 14pt; font-family:Helvetica, Arial, sans-serif; color: #000000; background-color: transparent; font-weight: 700; vertical-align: baseline;">${data.name}</span>\n`+
  `        <br>\n`+
  `          <span style="font-size: 10pt; line-height: 1.4; margin-top: 4pt;font-family:Helvetica, Arial, sans-serif; color:#000000; background-color: transparent;">${data.position} &nbsp;• &nbsp;NMLS ${data.nmls}</span>\n`+
  `          <br>\n`+
  `    </p>\n`+
  `  </td>\n`+
  `  </tr>\n`+
  `  <tr border="0" style="height: 35pt;">\n`+
  `    <td border="0" style="font-family:Helvetica, Arial, sans-serif; margin: 0px;  vertical-align: top; padding: 0pt; overflow: hidden;">\n`+
  `      <br>\n`+
  `    </td>\n`+
  `    <td border="0" colspan="2" style="padding-left: 5pt; font-family:Helvetica, Arial, sans-serif; margin: 0px;  vertical-align: top; padding-bottom: 5pt; overflow: hidden;">\n`+
  `      <p dir="ltr" style="font-size: 10pt; font-family:Helvetica, Arial, sans-serif; line-height: 1.4; margin-top: 10pt !important; margin-bottom: 0pt; background-color: transparent; font-weight: 700; vertical-align: baseline;">\n`+
  `        <a href="${data.applyUrl}" target="_blank" style="color:#05A1CA;text-decoration: underline;">Apply for Loan</a>\n`+
  `        <span style="margin-right: 10pt;"></span>\n`+
  `        <a href="${data.docsUrl}" target="_blank" style="color:#05A1CA;text-decoration: underline;">Send Documents</a>\n`+
  `    </p>\n`+
  `  </td>\n`+
  `</tr>\n`+
  `<tr border="0">\n`+
  `  <td style="height: 30px"></td>\n`+
  `  <td style="width: 25px; padding-left: 5pt; vertical-align: top;">\n`+
  `    <img src="https://lh6.googleusercontent.com/hC4O7pYPeEohR5ohFNNB2hfMKS7ZCOW6QoREQcS_Cs6A5PF8ZUUKhhQHj-hpiiP58k14PzI5dStsKc6DjqXtJMNGqHbOYbo2wNUDUtu4D3JxJ3dppMZvMVZPkVor4ACToQ1KaySw" width="12" height="12">\n`+
  `  </td>\n`+
  `  <td style="vertical-align: top">\n`+
  `    <p style="font-size: 10pt; font-family:Helvetica, Arial, sans-serif; line-height: 1.4; margin: 0">\n`+
  `      <a href="tel:${data.phone}" style="color:#777777;text-decoration: underline;">${data.phone}</a>\n`+
  `  </p>\n`+
  `</td>\n`+
  `</tr>\n`+
  `<tr border="0">\n`+
  `  <td style="height: 30px"></td>\n`+
  `  <td style="width: 25px; padding-left: 5pt; vertical-align: top;">\n`+
  `    <img src="https://lh3.googleusercontent.com/-8RVzp0eZt__UK5EpSbaHtXI-GHiHsnUNYmdpkCeUK9EZJJ8j3QmjqfU9n5tUtne2EZHaaH17i4vX91cxWBUOqG4Xr_0jN_9YewmAt1ZQqVXnkonIhmD4ZxsJ2JFrhfc0J53hQ9Y" width="12" height="12">\n`+
  `  </td>\n`+
  `  <td style="vertical-align: top">\n`+
  `    <p style="font-size: 10pt; font-family:Helvetica, Arial, sans-serif; line-height: 1.4; margin: 0">\n`+
  `      <a :href="${data.webSite}" target="_blank" style="color:#777777;text-decoration: underline;">${data.webSite}</a>\n`+
  `  </p>\n`+
  `</td>\n`+
  `</tr>\n`+
  `<tr border="0">\n`+
  `  <td style="height:55px"></td>\n`+
  `  <td style="width: 25px; padding-left: 5pt; vertical-align: top;">\n`+
  `    <img src="https://lh3.googleusercontent.com/RcBlNTMivk02JMq8KOevB8ZOPn62z7LleyHKt06gn--mNhFTMmvkwheaeiR-OtcWPrrEMXFHc2fxyguOIAab7w2lXOkGqLo7WUuikuQCOJocDA9ZPA7xIJ9ZDVT0ozD356Lu6XF0" width="12" height="12">\n`+
  `  </td>\n`+
 `   <td style="vertical-align: top">\n`+
  `    <p style="color: #777777;font-size: 10pt; font-family:Helvetica, Arial, sans-serif; line-height: 1.4; margin: 0">\n`+
  `      ${data.address}\n`+
  `      <br>\n`+
  `        ${data.country}\n`+
  `    </p>\n`+
  `  </td>\n`+
  `</tr>\n`+
`</tbody>\n`+
`</table>\n`+
`  <p style="margin: 0;padding: 0;">\n`+
`    <span style="line-height: 1.4; margin-top: 7pt;font-size: 9pt; font-family:Helvetica, Arial, sans-serif; color:#777777; background-color: transparent; vertical-align: baseline;">Clear Mortgage Capital, Inc. 1935005</span>\n`+
`  </p>\n`+
`  <p dir="ltr" style="margin: 0;padding: 0; color:#777777; font-family:Helvetica, Arial, sans-serif; font-size: small; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: -webkit-left; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; line-height: 1.728; margin-top: 10pt; margin-bottom: 0pt;"><span style="font-size: 9pt; font-family: Roboto, sans-serif; color: rgb(142, 148, 152); background-color: transparent; font-style: italic; vertical-align: baseline;">Confidentiality Notice: This email message, including any attachments, is for the sole use of the intended recipient(s) and may contain confidential and privileged information. Any unauthorized review, use, disclosure or distribution of this information is prohibited, and may be punishable by law. If this was sent to you in error, please notify the sender by reply email and destroy all copies of the original message. Please consider the environment before printing this email. Equal Opportunity Lender. www.nmlsconsumeraccess.org</span></p>\n`+
`</body>`+
`</html>`
  )
}
