<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Signature generator</h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container">
          <div class="row">
            <div class="col-6">
              <div class="form-group full-width">
                <label for="imageField">Photo</label>
                <input type="text" id="imageField" class="form-control" v-model="formData.image" @change="updateInfo">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group full-width">
                <label for="nameField">Name</label>
                <input type="text" id="nameField" class="form-control" v-model="formData.name" @change="updateInfo">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mt-3 full-width">
                <label for="positionField">Position</label>
                <input type="text" id="positionField" class="form-control" v-model="formData.position" @change="updateInfo">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mt-3 full-width">
                <label for="nmlsField">NMLS</label>
                <input type="number" id="nmlsField" class="form-control" v-model="formData.nmls" @change="updateInfo">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mt-3 full-width">
                <label for="phoneField">Phone</label>
                <input type="tel" v-mask="'(###)-###-####'" id="phoneField" class="form-control" v-model="formData.phone" @change="updateInfo">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mt-3 full-width">
                <label for="addressField">Address</label>
                <input type="text" id="addressField" class="form-control" v-model="formData.address" @change="updateInfo">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mt-3 full-width">
                <label for="countryField">Country</label>
                <input type="text" id="countryField" class="form-control" v-model="formData.country" @change="updateInfo">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mt-3 full-width">
                <label for="webField">Website</label>
                <input type="text" id="webField" class="form-control" v-model="formData.webSite" @change="updateInfo">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mt-3 full-width">
                <label for="applyField">Apply for Loan</label>
                <input type="text" id="applyField" class="form-control" v-model="formData.applyUrl" @change="updateInfo">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group full-width mt-3">
                <label for="docsField">Send docs URL</label>
                <input type="text" id="docsField" class="form-control" v-model="formData.docsUrl" @keyup="updateInfo">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mt-3">
                <input
                  type="checkbox"
                  name=""
                  id="showRateQuoteModal"
                  class="form-check-input m-0"
                  v-model="formData.showRateQuoteFunnel"
                  :value="formData.showRateQuoteFunnel"
                />
                <label for="showRateQuoteModal">Add Rate Quote Funnel link</label>
              </div>
              <div class="form-group full-width mt-2" v-if="formData.showRateQuoteFunnel">
                <label for="docsField">Get a Quote</label>
                <input type="text" id="docsField" class="form-control" v-model="formData.quoteUrl" @keyup="updateInfo">
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body-container">
          <div class="modal-body-container_title">
            Preview
          </div>
          <sign-preview :form-data="formData" />
        </div>
        <div class="modal-body-container">
          <div class="modal-body-container_title">
            HTML output
          </div>
          <div class="text-center mt-4" v-if="ui.updating">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <html-output v-else :form-data="formData" />
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <div class="d-flex">
          <base-button title="Copy to Clipboard" action="secondary-default" @click-btn="copy" />
          <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HtmlOutputTemplate } from '../includes/template.js';
import { HtmlOutputTemplateWithRateFunnel } from '../includes/template.js';
import SignPreview from "../includes/SignPreview";
import HtmlOutput from "../includes/HtmlOutput";
import BaseButton from '../../../components/BaseButton';
export default {
  name: 'ESignSettingsModal',
  components: {HtmlOutput, SignPreview, BaseButton},
  data() {
    return {
      formData: {
        image: 'https://via.placeholder.com/320',
        name: 'Name',
        position: 'Position',
        nmls: '',
        phone: '1233333333',
        address: 'Address',
        country: 'Country',
        webSite: 'www.website.com',
        applyUrl: '',
        docsUrl: '#',
        quoteUrl: '',
        showRateQuoteFunnel: false
      },
      ui: {
        updating: false,
      },
    }
  },
  methods: {
    updateInfo() {
      this.ui.updating = true

      setTimeout(() => {
        this.ui.updating = false
      }, 700)
    },
    copy() {
      /* Get message from textarea */
      let msg = HtmlOutputTemplate(this.formData)

      if (this.formData.showRateQuoteFunnel) {
        msg = HtmlOutputTemplateWithRateFunnel(this.formData)
      }

      /* Create textarea from copy message */
      let textarea = document.getElementById('htmlText')
      textarea.value = msg

      /* Select the text field */
      textarea.select()
      textarea.setSelectionRange(0, 99999)

      try {
       if (document.execCommand("copy") === true) {
         alert('You have generated a custom email signature. The HTML has been copied to your clipboard.');
       }
      }
      catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
      }
      finally {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-check-input {
  width: 18px;
  height: 18px;
  max-width: 18px;
  max-height: 18px;
  margin-right: 10px !important;
}
</style>
