<template>
  <div>
    <!--  Page Header  -->
    <base-page-header title="Resource Center" />

    <!--  Page Content  -->
    <div class="page-content">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            Letters
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-6 col-lg-4">
            <router-link :to="{ name: 'pre_approval_letters' }" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/icon-preapproval letter.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Pre-Approval Letter</div>
                  <div class="section-item-subtitle">Create and download letters</div>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-12">
          <div class="section-title">
            Marketing Resource
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-6 col-lg-4">
            <router-link :to="{ name: 'ad_wizard' }" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/ad-wizard-icon.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Ad Wizard</div>
                  <div class="section-item-subtitle">Create Magazine Ad</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 col-lg-4 mt-3 mt-md-0">
            <router-link :to="{ name: 'business_card_generator' }" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/business-icon.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Business Card</div>
                  <div class="section-item-subtitle">Generate Business Card</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 col-lg-4 mt-3 mt-lg-0">
            <router-link :to="{ name: 'house_flyer_editor' }" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/open-house-flyer-icon.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Open House Flyer</div>
                  <div class="section-item-subtitle">Marketing flyer for open house</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 col-lg-4 mt-3 mt-3">
            <router-link :to="{ name: 'homebuyer_guide' }" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/icon-home-buyer-guide-flyer.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Flyer: Home Buyer Guide</div>
                  <div class="section-item-subtitle">Share Home Buyer Guide with clients</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 col-lg-4 mt-3 mt-3">
            <router-link :to="{ name: 'yard_sign' }" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/icon-yard-sign.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Yard Sign: For Sale</div>
                  <div class="section-item-subtitle">Create your custom yard signs</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 col-lg-4 mt-3 mt-3">
            <router-link :to="{ name: 'brands' }" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/icon-brands.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Brands</div>
                  <div class="section-item-subtitle">CMC Brand Resources</div>
                </div>
              </div>
            </router-link>
          </div>
<!--          <div class="col-md-6 col-lg-4 mt-3 mt-3">-->
<!--            <a href="#" class="text-decoration-none section-item-link">-->
<!--              <div class="section-item">-->
<!--                <img src="@/assets/icons/icon-marketing help.svg" alt="">-->
<!--                <div class="section-item-info">-->
<!--                  <div class="section-item-title">Marketing Help</div>-->
<!--                  <div class="section-item-subtitle">Have questions about campaigns?</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </a>-->
<!--          </div>-->
          <div class="col-md-6 col-lg-4 mt-3 mt-3">
            <router-link :to="{name: 'training_videos'}" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/icon-training-videos.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Training Videos</div>
                  <div class="section-item-subtitle">Take in-depth training of Vision</div>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-md-6 col-lg-4 mt-3 mt-3">
            <router-link :to="{name: 'marketing_resource'}" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/price-quote-icon.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Marketing Resources</div>
                  <div class="section-item-subtitle">Marketing resources of Vision</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 col-lg-4 mt-3 mt-3">
            <div class="text-decoration-none section-item-link">
              <div class="section-item cursor-pointer" @click="openMerchandise">
                <img src="@/assets/icons/tshirt-cmc.svg" style="width: 60px; height: 60px" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Merchandise</div>
                  <div class="section-item-subtitle">CMC Clothing and Accessories</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mt-3 mt-3">
            <div class="text-decoration-none section-item-link" v-if="false" @click="modals.salesTrainingModal = true">
              <div class="section-item">
                <img src="@/assets/icons/wire-instructions-icon.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Sales Training</div>
                  <div class="section-item-subtitle">Sales Training Manual</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="section-title">
            Apps
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-6 col-lg-4">
            <div class="text-decoration-none section-item-link" @click="modals.eSignGenerating = true">
              <div class="section-item">
                <img src="@/assets/icons/email-signature-icon.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Email Signature</div>
                  <div class="section-item-subtitle">Generate Email Signature</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mt-3 mt-md-0">
            <a href="https://1drv.ms/u/s!AkdrQ5Y6cQuUgo97svUlVLO2j_X9CA?e=4Wb3Dh" target="_blank" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/one-drive-icon.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">CMC OneDrive</div>
                  <div class="section-item-subtitle">Shared files on OneDrive</div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-6 col-lg-4 mt-3 mt-lg-0">
            <div class="text-decoration-none section-item-link" @click="modals.wireInstructions = true">
              <div class="section-item">
                <img src="@/assets/icons/wire-instructions-icon.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Wire Instructions</div>
                  <div class="section-item-subtitle">CMC Bank Instructions</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mt-3">
            <a :href="backendUrl('/mlo/quote/')" target="_blank" class="text-decoration-none section-item-link">
              <div class="section-item">
                <img src="@/assets/icons/price-quote-icon.svg" alt="">
                <div class="section-item-info">
                  <div class="section-item-title">Quote Tool</div>
                  <div class="section-item-subtitle">Send quote to clients</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div v-if="false">
        <div class="col-12">
          <div class="section-title">
            Reports
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6 col-lg-4 mt-3 mt-lg-0">
              <div class="text-decoration-none section-item-link">
                <div class="section-item">
                  <img src="@/assets/icons/icon-report mcr.svg" alt="">
                  <div class="section-item-info">
                    <div class="section-item-title">Mortgage Call Report (MCR)</div>
                    <div class="section-item-subtitle">Quaterly reports in xml format</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <wire-instruction-modal v-if="modals.wireInstructions"
                              @close="modals.wireInstructions = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <sales-training-modal v-if="modals.salesTrainingModal"
                              @close="modals.salesTrainingModal = false" />
    </transition>


    <transition name="component-fade" mode="out-in">
      <e-sign-generating-modal v-if="modals.eSignGenerating"
                               @custom="modals.eSignSettings = true"
                               @close="modals.eSignGenerating = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <e-sign-settings-modal v-if="modals.eSignSettings"
                             @close="modals.eSignSettings = false" />
    </transition>
  </div>
</template>

<script>
import WireInstructionModal from "./modals/WireInstructionModal";
import ESignSettingsModal from "./modals/ESignSettingsModal";
import ESignGeneratingModal from "./modals/ESignGeneratingModal";
import BasePageHeader from "../../components/BasePageHeader";
import SalesTrainingModal from "./modals/SalesTrainingModal.vue";

export default {
  name: 'ResourceCenter',
  components: {SalesTrainingModal, ESignGeneratingModal, ESignSettingsModal, WireInstructionModal, BasePageHeader },
  data() {
    return {
      modals: {
        wireInstructions: false,
        salesTrainingModal: false,
        eSignSettings: false,
        eSignGenerating: false
      }
    }
  },
  methods: {
    openMerchandise () {
      window.open('https://clearmortgagecapitalstore.square.site/', '_blank')
    }
  }
}
</script>

<style lang="scss">
.content { min-height: auto!important; }
.page-content {
  padding: 30px;

  .section-title {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 24px;
  }

  .section-item-link {
    cursor: pointer;
    &.disabled {
      cursor: default;
      pointer-events: none;

      .section-item {
        opacity: .5;
      }
    }
  }

  .section-item {
    display: flex;
    align-items: center;
    border: 1px solid #DADADA;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 18px;
    transition: all .3s;

    &:hover {
      border: 1px solid #017AFF;
    }

    &-info {
      margin-left: 18px;
    }

    &-title {
      position: relative;
      width: fit-content;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 6px;

      &::after {
        position: absolute;
        display: block;
        content: '';
        bottom: 0;
        width: 0%;
        height: 1px;
        background: black;
        transition: all .3s;
      }
    }

    &-subtitle {
      color: rgba(0,0,0,.6);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}
</style>
