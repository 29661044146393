<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Sales Training</h3>
        <button class="btn btn-control" @click="$emit('close')">
          <img src="@/assets/icons/icon-remove.svg" alt="">
        </button>
      </div>
      <div class="modal-body pb-0">
        <div class="modal-body-container border-bottom-0">
          <pdf :src="src" :page="1" class="page-preview" :class="{active: ui.preloadPreview === false}"/>
        </div>
      </div>
      <div class="modal-footer">
        <base-button title="Next" action="secondary-default" />
        <base-button title="Close" action="secondary" class="ms-2"/>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import BaseButton from '../../../components/BaseButton.vue';

export default {
  components: {BaseButton},
  name: 'SalesTrainingModal',
  data() {
    return {
      ui: {
        copying: false
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      src: null,
      numPages: 0,
      selectedPage: 1,
    }
  },
  methods: {
    loadContent() {
      let headers = {
        'Content-Type': 'multipart/form-data',
        responseType: 'arraybuffer'
      };
      console.log(2)
      this.ui.loading = true;
      this.$http.post('https://app.clearmortgagecapital.com/hooks/v1/pdfs/show', {}, headers)
        .then(res => {
          this.src = pdf.createLoadingTask(res.data)
          this.src.promise.then(pdf => {
            this.ui.loading = false
            this.numPages = pdf.numPages
          })
        })
        .catch()
        .finally(() => this.ui.loading = false);
    },
  },
  mounted() {
    this.loadContent()
  },
}
</script>

<style lang="scss" scoped>
.modal {
  &-block {
    height: fit-content !important;
    margin: 150px auto !important;
    overflow-y: hidden !important;

    p {
      color: #000000;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }

    .modal-footer {
      bottom: unset;
    }
  }
}
</style>
